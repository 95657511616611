<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { debounce } from "lodash";
import appConfig from "@/app.config";
import activitySvc from "@/services/activity";

export default {
  page: {
    title: "Activity (TAT)",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Activity (TAT)",
      // items: [
      //   {
      //     text: "Activity",
      //     href: "#",
      //   },
      //   {
      //     text: "List",
      //     active: true,
      //   },
      // ],
      columns: [
        {
          field: "id",
          hidden: true,
        },
        {
          label: "Name",
          field: "nameTh",
          width: "30%",
        },
        {
          label: "Type",
          field: "activityType",
        },
        {
          label: "tourist Target",
          field: "touristTarget",
        },
        {
          label: "Activity Level",
          field: "activityLevel",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
        keywordSearch: "",
      },
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    onSearch: debounce(function (params) {
      this.updateParams({ page: 1, keywordSearch: params.searchTerm });
      this.$refs["tbl-activity-list"].reset();
      this.get();
    }, 1000),
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.get();
    },
    onPerPageChange(params) {
      this.updateParams({
        page: params.currentPage,
        perPage: params.currentPerPage,
      });
      this.get();
    },
    onSortChange(params) {
      this.updateParams(params);
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.get();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    get() {
      const page = this.serverParams.page;
      const perPage = this.serverParams.perPage;
      const keywordSearch = this.serverParams.keywordSearch;
      const sortBy = this.serverParams.sort[0].field;
      const sortType = this.serverParams.sort[0].type;

      activitySvc
        .getAll(page, perPage, keywordSearch, sortBy, sortType)
        .then((resp) => {
          if (resp.data.data === null) {
            return;
          }
          this.totalRecords = resp.data.data.total;
          this.rows = resp.data.data.data;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <!--
                v-on:column-filter="onColumnFilter"
              -->
              <vue-good-table
                ref="tbl-activity-list"
                :line-numbers="true"
                mode="remote"
                v-on:search="onSearch"
                v-on:page-change="onPageChange"
                v-on:per-page-change="onPerPageChange"
                v-on:sort-change="onSortChange"
                :totalRows="totalRecords"
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  perPageDropdown: [10, 20, 30, 40, 50, 100],
                  dropdownAllowAll: false,
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      size="sm"
                      variant="primary"
                      router-link
                      :to="`activity/detail?id=${props.formattedRow.id}`"
                    >
                      <i class="mdi mdi-eye font-size-16 align-middle"></i>
                      View
                    </b-button>
                  </span>
                  <span v-else>
                    {{
                      props.formattedRow[props.column.field]
                        ? props.formattedRow[props.column.field]
                        : "-"
                    }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
